
































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
@Component
export default class InsLogoGather extends Vue {
  @Prop({ default: '' }) private catKey!: string; // key值

  contents: object[] = [];

  swiperOptionMobile: object = {
    slidesPerView: 3,
    spaceBetween: 15,
    // centeredSlides: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    observer: true,
    observeParents: true
  };
  swiperOptionPC: object = {
    slidesPerView: 4,
    spaceBetween: 89,
    // centeredSlides: true,
    // pagination: {
    //   el: '.swiper-pagination',
    //   clickable: true
    // },
    navigation: {
      nextEl: '.swiper-button-next.gather-next',
      prevEl: '.swiper-button-prev.gather-prev'
    },
    observer: true,
    observeParents: true
  };

  // 获取关于cms内容列表
  getContents () {
    this.$Api.cms
      .getContentsByCatKeyEx({ Key: this.catKey, IsMobile: this.isMobile })
      .then(result => {
        this.contents = result.Data;
        console.log(result, 'logo_link');
      });
  }

  get isMobile () {
    return this.$store.state.isMobile;
  }

  mounted () {
    this.getContents();
  }

  @Watch('isMobile', { deep: true })
  onMediaChange () {
    this.getContents();
  }
}
